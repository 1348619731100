<template>
  <v-container class="pa-0">
    <v-container>
      <v-row justify="end" align="center">
        <v-col>
          <span class="text-h3">{{ subtitle }}</span>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" outlined @click="add(groupId)">
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span class="text-h5">追加</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tmpItems"
        hide-default-header
        hide-default-footer
        :items-per-page="-1"
        class="mt-4 elevation-1"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="(h, index) in headers" :key="index">
                <span class="primary--text text-h5">{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            handle=".handle"
            :options="{ animation: 300 }"
            @update="onmove(props.items)"
            @end="onend(props.items)"
            :sort="sortable"
          >
            <tr v-for="(element, index) in props.items" :key="index">
              <td class="text-left handle">
                <v-tooltip right v-if="sortable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-arrow-all </v-icon>
                  </template>
                  <span>ドラッグで上下に動かせます</span>
                </v-tooltip>
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ element.name }}</td>
              <td>
                <v-icon @click="edit(element)"> mdi-pencil </v-icon>
              </td>
              <td v-if="deletable">
                <v-icon @click="del(element.id)">mdi-close</v-icon>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-container>

    <!-- 部品 -->
    <v-container>
      <v-dialog v-model="delInfo.show" max-width="300" persistent>
        <v-card>
          <v-card-title> 削除してよろしいですか? </v-card-title>
          <v-card-text class="pb-6 pt-12 text-center">
            <v-btn class="mr-3" text @click="delInfo.show = false">
              キャンセル
            </v-btn>
            <v-btn color="primary" text @click="confirmDel(delInfo)">
              削除する
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
const _cloneDeep = require("lodash.clonedeep");

export default {
  components: {
    draggable,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: "",
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    headers: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    datas: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    handleAdd: {
      type: Function,
      required: true,
    },
    handleEdit: {
      type: Function,
      required: true,
    },
    handleDel: {
      type: Function,
      required: false,
    },
    handleOnSortMove: {
      type: Function,
      required: false,
    },
    handleOnSortEnd: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    tmpItems: [],
    delInfo: { show: false, index: -1 },
  }),
  watch: {
    datas: {
      handler: function (newValue) {
        this.tmpItems = _cloneDeep(newValue);
        console.log("handler", this.tmpItems);
      },
      deep: true,
      immediate: true, // 初回読込時も動作させる
    },
  },
  methods: {
    // 追加 押下
    add: function (groupId) {
      this.handleAdd(groupId);
    },
    // 編集 押下
    edit(element) {
      this.handleEdit(element);
    },
    // 削除 押下
    del(id) {
      // 確認ダイアログ
      console.log(`del: id=${id}`);
      this.delInfo = { show: true, id: id };
    },
    confirmDel(delInfo) {
      console.log(`confirmDel: ${JSON.stringify(delInfo)}`);
      const targetId = delInfo.id;
      if (!targetId) {
        throw new Error(`invalid targetId: ${targetId}`);
      }
      this.delInfo = { show: false };
      this.handleDel(delInfo);
    },
    // ドラッグイベント
    onmove(updatedItems) {
      // console.log(`onmove: ${JSON.stringify(updatedItems)}`);
      if (!this.sortable) {
        return; // 何もしない
      }
      if (this.handleOnSortMove) {
        this.handleOnSortMove({
          groupId: this.groupId,
          items: updatedItems,
        });
      }
    },
    onend(updatedItems) {
      // console.log(`onend: ${JSON.stringify(updatedItems)}`);
      if (!this.sortable) {
        return; // 何もしない
      }
      if (this.handleOnSortEnd) {
        this.handleOnSortEnd({
          groupId: this.groupId,
          items: updatedItems,
        });
      }
    },
  },
};
</script>
<style scoped>
/* ソート部品 */
.handle:hover {
  cursor: grab;
}
</style>
